import { makeStyles, ClickAwayListener, Fab, Grow, Icon, Popper, Grid, IconButton, Button } from '@material-ui/core'
import React, {useState} from 'react'
import logo from '../../../assets/logo.png'
import backgroud from '../../../assets/background_whatsapp.jpg'
import DisplayText from '../../Texts/DisplayText'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme=>({
    chatCard:{
        borderRadius:20,
        width:400,
        marginBottom:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    topCard:{
        backgroundColor:'#075E54',
        color:'white',
        padding:12,
        borderRadius:'20px 20px 0px 0px'
    },
    chatBody:{
        position:'relative',
        height:120,
    },
    chatBottom:{
        backgroundColor:'white',
        padding:20,
        borderRadius:'0px 0px 20px 20px'
    },
    backgroudContainer:{
        position:'absolute',
        width:'100%',
        height:'100%'
    },
    backgroud:{
        width:'100%',
        height:'100%',
        objectFit:'cover'
    },
    message:{
        position:'absolute',
        backgroundColor:'white',
        borderRadius:12,
        padding:8,
        color:'white',
        zIndex:5,
        top:16,
        left:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    chatButton:{
        backgroundColor:'#4AB969',
        color:'white'
    },
    startChatButton:{
        borderRadius:24, 
        backgroundColor:'#4AB969',
        color:'white',
        '&:hover':{
            backgroundColor:'#075E54',
        }
    },
    link:{
        color:'white',
        textDecoration:'none',
    }
}))

const ChatButton = () =>{

    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    let buttonContent = (
        <div className={classes.chatCard}>
            <div className={classes.topCard}>
                <Grid container >
                    <Grid item xs={12} >
                        <Grid container justify='flex-end'>
                            <Grid item>
                                <IconButton size='small' onClick={()=>setAnchorEl(null)}>
                                    <Icon style={{color:'white'}}>clear</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <div >
                        <img src={logo} alt='' style={{width:60}} />
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <DisplayText variant='subtitle1' style={{color:'white'}}>UBELENS</DisplayText>
                        <DisplayText variant='caption' style={{color:'white'}}>Usualmente responde en unas horas</DisplayText>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.chatBody}> 
                <div className={classes.message}>
                    <DisplayText style={{color:blueGrey[700]}} >UBELENS</DisplayText>
                    <DisplayText>¡Hola!</DisplayText>
                    <DisplayText>¿En que podemos ayudarte?</DisplayText>
                    {/* <DisplayText>Ola</DisplayText>
                    <DisplayText>k ase?</DisplayText> */}
                </div>
                <div className={classes.backgroudContainer}>
                    <img src={backgroud} alt='' className={classes.backgroud} />
                </div>
            </div>
            <div className={classes.chatBottom}>
                <a className={classes.link} href='https://wa.me/573168287735' target='_blanck'>
                    <Button className={classes.startChatButton} fullWidth
                    startIcon={<Icon>whatsapp</Icon>}>
                        Comenzar chat
                    </Button>
                </a>
            </div>
        </div>
    )

    return(
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>  
            <Fab  className={classes.chatButton} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Icon>whatsapp</Icon>
            </Fab>
        </div>
    )

}

export default ChatButton