import React, {useEffect,useState} from 'react'
import Page from '../../../components/Structure/Page/Page'
import { request_add_lineitem, request_create_order, request_product } from './requests'
import { onGetFormatNumber } from '../../../shared/utility';
import {connect} from 'react-redux'
import * as actionTypes from '../../../store/actions'
import ProductContainer from '../components/ProductContainer';

const onGetProductCatalogs = (obj) => {
    const {spheres, cylinders, axes, adds, colors, dominances} = obj
    let catalogs = {spheres, cylinders, axes, adds, colors, dominances}
    return catalogs
}


const ProductView = props => {

    const {match, user, onUpdateCartItems, onUpdateLoginModal} = props
    const {id} = match.params
    
    const [updating, setUpdating] = useState(false);
    const [product, setProduct] = useState({})
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _product = await request_product(id)
            setProduct(_product)
            setLoading(false)
        } catch (error) {
            console.log("Hubo un error")
            console.log(error.response)
        }}
        initModule()
    },[])

    const onSubmit = async(line_items) => {
        setUpdating(true)        
        try {
            const orderID = localStorage.getItem('orderID');
            console.log(line_items)
            if(orderID){
                console.log("Ya existe carrito")
                if(user){
                    console.log(orderID)
                    let order
                    for(let i=0; i<line_items.length; i++){
                        order = await request_add_lineitem(orderID, line_items[i]);
                    }
                    onUpdateCartItems(order.line_items.length)
                    props.history.push('/cart')
                }
            }else{
                console.log("No existe carrito")
                if(user){
                    console.log(user)
                    const data2create = {user_id:user._id}
                    let order = await request_create_order(data2create);
                    const orderID = order._id
                    localStorage.setItem('orderID', orderID)
                    for(let i=0; i<line_items.length; i++){
                        order = await request_add_lineitem(orderID, line_items[i]);
                    }
                    onUpdateCartItems(order.line_items.length)
                    props.history.push('/cart')
                }else{
                    console.log('Se necesita iniciar sesión')
                    onUpdateLoginModal(true)
                }
            }
        } catch (error) {
            console.log(error)
        }
        setUpdating(false) 
    }

    let productPrice = ''
    let productTitle = ''
    let catalogs = {}
    let details = []
    let specifications = []

    if(product && product.title){
        //console.log(product)
        productPrice = `$ ${onGetFormatNumber(product.price)}`
        catalogs = onGetProductCatalogs(JSON.parse(product.variants))
        details = product.details ? JSON.parse(product.details) : []
        specifications = product.specifications ? JSON.parse(product.specifications) : []
    }
    
    let breadcrumbs = [
        {id:1, label:'Productos', url:`/`},
        {id:1, label:`${productTitle ? productTitle : ''}`, url:`/products/${id}`, selected:true},
    ]

    return(
        <Page breadcrumbs={breadcrumbs} loading={loading}>
            <ProductContainer 
                product_id={product._id}
                title={product.title}
                image={product.image}
                description={product.long_description}
                specifications={specifications} 
                details={details}
                price={productPrice}
                warranty={product.warranty}
                catalogs={catalogs}
                loading={updating}
                available={product.available}
                onSubmit={onSubmit}
            />
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
        onUpdateLoginModal: (login_modal) => dispatch({type: actionTypes.AUTH_LOGIN_MODAL, login_modal})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductView) 