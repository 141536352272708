import React, {useState, useEffect} from 'react'
import { Fab, Hidden, Icon, makeStyles } from '@material-ui/core'
import Topbar from '../Topbar/Topbar'
import Footer from '../Footer/Footer'
import { withRouter } from 'react-router-dom'
import SingInModal from '../../../modals/SignInModal/SingInModal'
import ForgottenPasswordModal from '../../../modals/ForgottenPasswordModal/ForgottenPasswordModal'
import ConfirmationLinkModal from '../../../modals/ConfirmationLinkModal/ConfirmationLinkModal'
import { isTokenValid } from '../../../shared/utility'
import {connect} from 'react-redux'
import * as actionTypes from '../../../store/actions'
import SignUpModal from '../../../modals/SignUpModal/SignUpModal'
import { request_mercadopago_payment, request_order, request_profile, request_reauth, request_update_order, request_update_payment } from './requests'
import Snackbar from '../../Overlays/Snackbar'
import CookiesBar from '../../Overlays/CookiesBar'
import moment from 'moment-timezone'
import Sidebar from '../Sidebar/Sidebar'
import { ORDER_STATUS_TYPES, PAYMENT_STATUSES } from '../../../variables/config'
import ChatButton from '../../Actions/Buttons/ChatButton'


const useStyles = makeStyles(theme => ({
    root:{
        background:'white',//'#f1f5f8',//'#f8f8f8',//'#f4f6f8',
        position:'relative'
    },
    subroot:{
        minHeight:'calc(100vh - 100px)',
        //background:'red'
    },
    chatButtonContainer:{
        position:'fixed',
        bottom:30,
        right:30
    },
    chatButton:{
        backgroundColor:'#00bb2d',
        color:'white'
    }
}))

const Frame = props => {


    const classes = useStyles()
    const [modals, setModals] = useState({
        signin:false,
        signup:false,
        forgottenpassword:false,
        confirmlink:false,
    })

    const [cookies, setCookies] = useState(true)
    const [reauth, setReauth] = useState(false)

    //sidebar
    const [drawer, setDrawer] = useState(false)

    const {isauth, cartItems, history, login_modal, signup_modal, recover_password_modal, confirm_link} = props
    const {onChangeAuthStatus, onUpdateCartItems, onUpdateUser,onUpdateSignInModal, onUpdateSingUpModal, onUpdateRecoverPasswordModal, onUpdateConfirmLink} = props

    useEffect(() => {
        const initModule = async() => {try {
            //cookies
            let cFlag = localStorage.getItem("cookieFlag");
            if(cFlag === '1') setCookies(false)

            const validToken = isTokenValid()
            onChangeAuthStatus(validToken)
            if(validToken){
                const user = await request_profile()
                console.log('Actualzar usuario')
                onUpdateUser(user)
            }
            const orderID = localStorage.getItem('orderID')
            if(orderID){
                const _order = await request_order(orderID)

                
                // Revisar la order si tiene un pago pendiente





                if(_order.status_id !== 1){
                    localStorage.removeItem('orderID')
                }else{

                    if(_order.status_id === ORDER_STATUS_TYPES.PENDING){
                        console.log('Revisar pedido')
                        if(_order.payment){
                            const {token_id, payment_url, payment_id, gateway} = _order.payment
                            const order_id = orderID
                            console.log(_order.payment)
                            if(gateway === 'mercadopago'){
                                console.log("Revisar mercado pago")
                                const _payment = await request_mercadopago_payment(order_id)
                                console.log(_payment)
                                if(_payment.results && _payment.results.length){
                                    // There is a payment
                                    const last_item = _payment.results.length - 1
                                    const mp_payment = _payment.results[last_item]
                                    console.log(mp_payment)
                                    if(mp_payment.status === 'approved' || mp_payment.status === 'authorized'){
                                        console.log('Payment status is aprroved or authorized, therefore the order will be mark as completed')
                                        let data2send = {payment_id:mp_payment.id}
                                        if(mp_payment.status === 'approved') data2send.status_id = PAYMENT_STATUSES.COMPLETED
                                        if(mp_payment.status === 'authorized') data2send.status_id = PAYMENT_STATUSES.COMPLETED
                                        await request_update_payment(order_id, data2send)
                                        await request_update_order(order_id, {status_id:ORDER_STATUS_TYPES.CONFIRMED})
                                        const _updated_order = await request_order(order_id)
                                        localStorage.removeItem('orderID')
                                    }else{
                                        console.log(mp_payment.status)
                                        console.log(_order.payment.status_id)
                                        if(_order.payment.status_id === PAYMENT_STATUSES.PENDING){
                                            await request_update_payment(order_id, {status_id: mp_payment.status === 'in_process' ? PAYMENT_STATUSES.INPROGRESS : PAYMENT_STATUSES.ERROR})
                                            const _updated_order = await request_order(order_id)
                                            onUpdateCartItems(_updated_order.line_items.length)
                                        }
                                        if(_order.payment.status_id === PAYMENT_STATUSES.ERROR && mp_payment.status === 'in_process'){
                                            await request_update_payment(order_id, {status_id: mp_payment.status === 'in_process' ? PAYMENT_STATUSES.INPROGRESS : PAYMENT_STATUSES.ERROR})
                                            const _updated_order = await request_order(order_id)
                                            onUpdateCartItems(_updated_order.line_items.length)
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }else{
                        console.log('Payment audit is not neccesary')
                    }


                    
                }
            }
        } catch (error) {
            console.log(error)
        }}
        initModule()
    }, [])

    const mouseMoveEvent = async(e) => {
        try {
          const data = JSON.parse(localStorage.getItem('token_data')) 
          if(data){
            if(!reauth){
              const serverTime = data.exp
              const localTime = moment().unix()
              if(localTime < serverTime){ // valid token
                if((localTime + 15*60) > serverTime){ // expiration date soon 
                  setReauth(true)
                  await request_reauth()
                  setReauth(false)
                }
              }else{
                localStorage.removeItem('token_data');
                localStorage.removeItem('orderID');
              }
            }
          }else{
            localStorage.removeItem('token_data');
            localStorage.removeItem('orderID');
          }
        } catch (error) {
        }
      }

    const onOpenModal = (item) => {
        if(item === 'signin' || item === 'signup' || item === 'forgottenpassword' || item === 'confirmlink'){
            let temp = {...modals}
            temp[item] = true
            setModals(temp)
            //return
        }
        
        if(item === 'signin') onUpdateSignInModal(true)
        if(item === 'signup') onUpdateSingUpModal(true)
        if(item === 'forgottenpassword') onUpdateRecoverPasswordModal(true)
        if(item === 'confirmlink') onUpdateConfirmLink(true)

        if(item === 'profile') history.push('/profile')
        if(item === 'orders') history.push('/orders')

    }

    const onChangeModal = (from, to) => {
        let temp = {...modals}
        temp[from] = false
        temp[to] = true
        setModals(temp)

        //open the modals
        if(temp.signin === true) {
            onUpdateSignInModal(true)
            onUpdateSingUpModal(false)
        }
        if(temp.signup === true) onUpdateSingUpModal(true)
        if(temp.forgottenpassword === true) onUpdateRecoverPasswordModal(true)
        if(temp.confirmlink === true) onUpdateConfirmLink(true)
    }

    const onSelectedLink = (url) => {
        if(url){
            props.history.push(url)
            window.scrollTo(0,0)
        } 
    }

    const onLogOut = () => {
        localStorage.removeItem('token_data')
        localStorage.removeItem('orderID')

        onChangeAuthStatus(false)
        onUpdateUser(null)
        onUpdateCartItems(0)
    }

 
    /* const onShowMoreInfo = (url) => {
        console.log('more info')
        console.log(url)
        window.scrollTo(0,0)
        history.push(url)
    } */

    function onAcceptedCookies() {
        console.log('Acepto')
        localStorage.setItem("cookieFlag", 1);
        setCookies(false);  
    }

   // console.log(recover_password_modal)
   


    return(
        <div className={classes.root} onMouseMove={mouseMoveEvent} >
            {cookies ? <CookiesBar open={cookies} onAcceptedCookies={onAcceptedCookies}/> : null}
            <SingInModal open={login_modal} onClose={() => onUpdateSignInModal(false)} onOpenModal={onOpenModal}/>
            <SignUpModal open={signup_modal} onClose={() => onUpdateSingUpModal(false)} onOpenModal={onOpenModal} onChangeModal={onChangeModal}/>
            <ForgottenPasswordModal open={recover_password_modal} onClose={() => onUpdateRecoverPasswordModal(false)}/>
            <ConfirmationLinkModal open={confirm_link} onClose={() => onUpdateConfirmLink(false)}/>
            <Topbar onSelectedLink={onSelectedLink} onModalSelected={onOpenModal} isAuth={isauth} onLogOut={onLogOut} cartItems={cartItems} onToggleDrawer={()=>{setDrawer(true)}}/>
            <Hidden mdUp>
                <Sidebar history={history} drawer={drawer} onToggleDrawer={()=>{setDrawer(!drawer)}}/>
            </Hidden>
            <div className={classes.subroot}>
                {props.children}
            </div>
            <Footer history={history}/>
            <div className={classes.chatButtonContainer}>
                <ChatButton />
            </div>
        </div>
    )

    
    
}

const mapStateToProps = state => {
    return {
        isauth: state.isauth,
        cartItems: state.cartItems,
        login_modal:state.login_modal,
        signup_modal:state.signup_modal,
        recover_password_modal:state.recover_password_modal,
        confirm_link:state.confirm_link
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeAuthStatus: (isauth) => dispatch({type: actionTypes.CHANGE_AUTH_STATUS, isauth }),
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateSignInModal: (login_modal) => dispatch({type: actionTypes.AUTH_LOGIN_MODAL, login_modal }),
        onUpdateSingUpModal: (signup_modal) => dispatch({type: actionTypes.AUTH_SINGUP_MODAL, signup_modal }),
        onUpdateRecoverPasswordModal: (recover_password_modal) => dispatch({type: actionTypes.AUTH_RECOVER_PASSWORD_MODAL, recover_password_modal }),
        onUpdateConfirmLink: (confirm_link) => dispatch({type: actionTypes.AUTH_CONFIRM_LINK, confirm_link }),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Frame))