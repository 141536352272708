import { blueGrey, red } from "@material-ui/core/colors";
import axios from 'axios'

const primary_color = '#2A368B'
const secondary_color = '#0291ff'

export const palette = {
    texts:{
        primary:primary_color,
        secondary:secondary_color,
        white:'#FFF',
        bluegrey_light:blueGrey[300],
        bluegrey:blueGrey[500],
        bluegrey_dark:blueGrey[700],
        bluegrey_super_dark:blueGrey[900],
        red:red[700]
    }
}

export const config_data = {
    S3_SERVER_URL: 'https://ubelens-dev-public.s3.amazonaws.com/',
    HOST:'http://localhost:3000/',
    MP_ACCESS_TOKEN:'TEST-1850324247623510-100516-c33f05d41007cc04560686f366e05e31-652726497',
    RECAPTCHA_WEB_KEY:'6LedrJsaAAAAAFUdud0xN0j2Jq7lMdbkqCkkOgd9',
    RECAPTCHA_SECRET_KEY:'6LedrJsaAAAAAJRLAlxOulDwmQzMLPA9edG2TG1C',
}

export const EYE_CONFIG = {
    LEFT:'Ojo izquierdo',
    RIGHT:'Ojo derecho',
    EQUAL:'Misma fórmula'
}

export const ORDER_STATUS_TYPES = {
    PENDING:1,
    CONFIRMED:2,
    PREPARING:3,
    SENDING:4,
    DELIVERED:5,
    COMPLETED:6,
    CANCELLED:7
}

export const PAYMENT_STATUSES = {
    PENDING:1,
    COMPLETED:2,
    WITHDRAW:3,
    ERROR:4,
    CANCELLED:5,
    PARTIAL:6
}





export const mainServer = axios.create({
    baseURL: "https://devapi.ubelens.com/api",//"http://127.0.0.1:3030",//"http://3.228.215.255/api2020",//"http://127.0.0.1:3030", // 
    headers: {'Content-Type': 'application/json'},
})

export const mpServer = axios.create({
    baseURL: "https://api.mercadopago.com", // "http://3.228.215.255/api2020",
    headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${config_data.MP_ACCESS_TOKEN}`},
})

export const USERNAME_API = 'xandbox'
export const PASSWORD_API = 'tnHap6uKLUA57sTrcNzKQqAYdNCKfLwE'
export const BASIC_AUTH_HEADER = `Basic eGFuZGJveDp0bkhhcDZ1S0xVQTU3c1RyY056S1FxQVlkTkNLZkx3RQ==`

export const CONTACTLENSES_CATEGORIES = {
    "miopia-o-hipermetropia":"lentes,miopia",
    "astigmatismo":"lentes,astigmatismo",
    "presbicia":"lentes,presbicia",
    "lentes-cosmeticos":"lentes,cosmeticos",
    "uso-diario":"lentes,diario",
    "uso-quincenal":"lentes,quincenal",
    "uso-mensual":"lentes,mensual",
    "air-optix":"lentes,air optix",
    "dailies-aqua-comfort-plus":"lentes,dailies aqua",
    "freshlook":"lentes,freshlook",
    "soflens":"lentes,soflens",
    "pure-vision-2":"lentes,pure vision",
    "ultra":"lentes,ultra",
    "biomedics":"lentes,biomedics",
    "avaira-vitality":"lentes,avaira",
    "biofinity":"lentes,biofinity",
    "clariti":"lentes,clarity",
    "1-day":"lentes,1-day",
    "alcon":"lentes,alcon",
    "bausch-lomb":"lentes,bausch",
    "coopervision":"lentes,coopervision",
    "johnson-johnson":"lentes,johnson",
}