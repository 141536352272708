import React, {useRef} from 'react'
import Slider from "react-slick";
import { makeStyles, Grid, IconButton, Icon } from '@material-ui/core';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from '../../Texts/Heading';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        paddingTop:60,
        paddingBottom:40,
        width:'100%'
        //borderTop:`1px solid ${blueGrey[50]}`,
        //borderBottom:`1px solid ${blueGrey[50]}`
    },
    container:{
        padding:theme.spacing(1),
        paddingTop:theme.spacing(2)
    },
    imageContainer:{
        width:150,
        height:150,
        position:'relative',
        margin:'auto',
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },
    iconbutton:{
        background:theme.palette.secondary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    }

}))

const CustomerSlider = props => {

    const {brands} = props
    const classes = useStyles()
    const sliderRef = useRef(null)

    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: "8px",
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3500,
        easing: "linear",
        arrows:false,
        responsive:[
            /* {
                breakpoint: 1280,
                settings: {
                    dots:true,
                    infinite:false,
                    speed:500,
                    centerPadding: "8px",
                    slidesToShow:4,
                    slidesToScroll:1,
                    initialSlide:0,
                    dots:false,
                }
            }, */
            {
                breakpoint: 600,
                settings: {
                    infinite:false,
                    speed:500,
                    centerPadding: "8px",
                    slidesToShow:1,
                    slidesToScroll:1,
                    dots:false,
                    arrows:true
                }
            },
        ]
        
    };
    

    return(
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Heading align='center' size='extraLarge' >Nuestras marcas</Heading>
                </Grid>
                <Grid item xs={12}>
                    <Grid container wrap='nowrap' alignItems='center'>
                        <Grid item xs={1}>
                            <IconButton size='small' className={classes.iconButton} onClick={() => sliderRef.current.slickPrev()}>
                                <Icon>arrow_back_ios</Icon>
                            </IconButton> 
                        </Grid>
                        <Grid item xs={10}>
                            <Slider {...settings} ref={sliderRef}>
                                {brands.map((item,key)=>{
                                    return(
                                        <div key={key.toString()}>
                                            <div className={classes.imageContainer}>
                                            <img src={item.image} alt={''} className={classes.image}/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </Grid>
                        <Grid item xs={1}>
                            <div style={{display:'flex', justifyContent:'flex-end'}}>
                            <IconButton size='small' className={classes.iconButton} onClick={() => sliderRef.current.slickNext()}>
                                <Icon>arrow_forward_ios</Icon>
                            </IconButton> 
                            </div>
                        </Grid>
                    </Grid>
                </Grid>          
            </Grid>
            
        </div>
    )
}

export default CustomerSlider

