import React, {useState} from 'react'
import { Fade, Grid, Hidden, makeStyles } from '@material-ui/core'
import ProductDetails from './ProductDetails'
import DisplayText from '../../../components/Texts/DisplayText'
import FormulaContainer from './FormulaContainer'
import freeDeliverImage from '../../../assets/freeDeliverOriginal.png'
import SimpleProductForm from './SimpleProductForm'
import { config_data } from '../../../variables/config'
import noproduct from '../../../assets/noproduct.png'
import {red} from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    root:{
        padding:theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            padding:0
        },
    },
    imageContainer:{
        width:500,
        height:500,
        position:'relative',
        margin:'auto',
        marginTop:theme.spacing(4),
        marginBottom:theme.spacing(4),
        [theme.breakpoints.only('md')]: {
            width:400,
            height:400,
        },
        [theme.breakpoints.down('sm')]: {
            width:200,
            height:200,
        },
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain'
    },
    priceContainer:{
        paddingBottom:theme.spacing(4)
    },
    notAvailableContainer:{
        backgroundColor:red[700],
        color:'white',
        borderRadius:24,
        //width:'100%',
        //display:'flex',
        //justifyContent:'center',
        //alignItems:'center',
        padding:theme.spacing(2)
    }
}))

const ProductContainer = (props) => {

    const classes = useStyles()
    const {image, description, details, specifications, title, price, warranty, catalogs, onSubmit, loading, type, product_id, available} = props
    const [error, setError] = useState(true)


    const onImageLoad = (e) => setError(false)
    const onError = (e) => setError(true)
    

    let selectedImage = image ? `${config_data.S3_SERVER_URL}${image}` : null

    return(
        <div className={classes.root}>
            <Grid container spacing={3} justify='center'>
                <Grid item xs={12} md={6}>
                    <Grid container justify='center'>
                        <Hidden mdUp>
                        <Grid item xs={12}>
                            <DisplayText size='large' color='secondary'>{title}</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.priceContainer}>
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <DisplayText size='large' color='bluegrey_dark' >{price}</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <img src={freeDeliverImage} alt='' width={70}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            <div className={classes.imageContainer}>
                            {error ? <img alt='' src={noproduct} className={classes.image}/> : null}
                            <Fade in={!error}><img alt='' src={selectedImage} className={classes.image} onLoad={onImageLoad} onError={onError} /></Fade>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ProductDetails 
                                description={description} 
                                details={details} 
                                specifications={specifications}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                        <Hidden smDown>
                        <Grid item xs={12}>
                            <DisplayText size='superLarge' color='secondary'>{title}</DisplayText>
                        </Grid>
                        <Grid item xs={12}>
                            <div >
                                <Grid container alignItems='center'>
                                    <Grid item xs>
                                        <DisplayText size='superLarge' color='bluegrey_dark' >{price}</DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <img src={freeDeliverImage} alt='' width={120}/>
                                    </Grid>                            
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item >
                            <div className={available ? classes.priceContainer : classes.notAvailableContainer}>
                                <DisplayText color='white' >{available ? null : 'PRODUCTO NO DISPONIBLE'}</DisplayText>
                            </div>
                        </Grid>
                        </Hidden>
                        <Grid item xs={12}>
                            {type === 'generic' ? (
                                <SimpleProductForm product_id={product_id} onSubmit={onSubmit} loading={loading} warranty={warranty}/>
                            ): (
                                <FormulaContainer product_id={product_id} onSubmit={onSubmit} catalogs={catalogs} loading={loading} warranty={warranty} type={type}/>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    )
}

export default ProductContainer